<template>
    <section id="choose_destinations_area" class="section_padding_top">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>Choose your destinations</h2>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="choose_desti_wrapper">
                        <div class="choose_des_inner_wrap">
                            <div class="choose_boxed_inner">
                                <img src="../../assets/img/destination/home-two-des-1.png" alt="img">
                                <div class="choose_img_text">
                                    <h2>London | $13,500</h2>
                                    <h3>7 days 6 nights</h3>
                                </div>
                            </div>
                            <div class="flep_choose_box">
                                <div class="flep_choose_box_inner">
                                    <div class="rating_outof">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <h5>5 Out of 5</h5>
                                    </div>
                                    <h2>China | $6000</h2>
                                    <h3>8 days 7 nights</h3>
                                    <p>Cupidatat consectetur ea cillum laborum labore veniam proident consectetur
                                        excepteur amet pariatur et laboris sint laborum.</p>
                                    <router-link to="/top-destinations-details">Tour details</router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="choose_desti_wrapper">
                        <div class="choose_des_inner_wrap">
                            <div class="choose_boxed_inner">
                                <img src="../../assets/img/destination/home-two-des-2.png" alt="img">
                                <div class="choose_img_text">
                                    <h2>Gangtok | $3,500</h2>
                                    <h3>7 days 6 nights</h3>
                                </div>
                            </div>
                            <div class="flep_choose_box">
                                <div class="flep_choose_box_inner">
                                    <div class="rating_outof">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <h5>5 Out of 5</h5>
                                    </div>
                                    <h2>Gangtok | $3,500</h2>
                                    <h3>7 days 6 nights</h3>
                                    <p>Cupidatat consectetur ea cillum laborum labore veniam proident consectetur
                                        excepteur amet pariatur et laboris sint laborum.</p>
                                    <router-link to="/top-destinations-details">Tour details</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="choose_desti_wrapper">
                        <div class="choose_des_inner_wrap">
                            <div class="choose_boxed_inner">
                                <img src="../../assets/img/destination/home-two-des-3.png" alt="img">
                                <div class="choose_img_text">
                                    <h2>Phuket | $2,500</h2>
                                    <h3>7 days 6 nights</h3>
                                </div>
                            </div>
                            <div class="flep_choose_box">
                                <div class="flep_choose_box_inner">
                                    <div class="rating_outof">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <h5>5 Out of 5</h5>
                                    </div>
                                    <h2>Phuket | $2,500</h2>
                                    <h3>7 days 7 nights</h3>
                                    <p>Cupidatat consectetur ea cillum laborum labore veniam proident consectetur
                                        excepteur amet pariatur et laboris sint laborum.</p>
                                    <router-link to="/top-destinations-details">Tour details</router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="choose_desti_wrapper">
                        <div class="choose_des_inner_wrap">
                            <div class="choose_boxed_inner">
                                <img src="../../assets/img/destination/home-two-des-4.png" alt="img">
                                <div class="choose_img_text">
                                    <h2>Sydney | $6,000</h2>
                                    <h3>4 days 3 nights</h3>
                                </div>
                            </div>
                            <div class="flep_choose_box">
                                <div class="flep_choose_box_inner">
                                    <div class="rating_outof">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <h5>5 Out of 5</h5>
                                    </div>
                                    <h2>Sydney | $6,000</h2>
                                    <h3>4 days 3 nights</h3>
                                    <p>Cupidatat consectetur ea cillum laborum labore veniam proident consectetur
                                        excepteur amet pariatur et laboris sint laborum.</p>
                                    <router-link to="/top-destinations-details">Tour details</router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="choose_desti_wrapper">
                        <div class="choose_des_inner_wrap">
                            <div class="choose_boxed_inner">
                                <img src="../../assets/img/destination/home-two-des-5.png" alt="img">
                                <div class="choose_img_text">
                                    <h2>Gangtok | $3,500</h2>
                                    <h3>9 days 8 nights</h3>
                                </div>
                            </div>
                            <div class="flep_choose_box">
                                <div class="flep_choose_box_inner">
                                    <div class="rating_outof">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <h5>5 Out of 5</h5>
                                    </div>
                                    <h2>Gangtok | $3,500</h2>
                                    <h3>9 days 8 nights</h3>
                                    <p>Cupidatat consectetur ea cillum laborum labore veniam proident consectetur
                                        excepteur amet pariatur et laboris sint laborum.</p>
                                    <router-link to="/top-destinations-details">Tour details</router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="choose_desti_wrapper">
                        <div class="choose_des_inner_wrap">
                            <div class="choose_boxed_inner">
                                <img src="../../assets/img/destination/home-two-des-6.png" alt="img">
                                <div class="choose_img_text">
                                    <h2>Pattaya | $4,200</h2>
                                    <h3>5 days 6 nights</h3>
                                </div>
                            </div>
                            <div class="flep_choose_box">
                                <div class="flep_choose_box_inner">
                                    <div class="rating_outof">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <h5>5 Out of 5</h5>
                                    </div>
                                    <h2>Pattaya | $4,200</h2>
                                    <h3>5 days 6 nights</h3>
                                    <p>Cupidatat consectetur ea cillum laborum labore veniam proident consectetur
                                        excepteur amet pariatur et laboris sint laborum.</p>
                                    <router-link to="/top-destinations-details">Tour details</router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>

export default {
    name: "ChooseDestination"
};
</script>