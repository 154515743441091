<template>
    <div>
        <header class="main_header_arae">
            <!-- Top Bar -->
            <div class="topbar-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <ul class="topbar-list">
                                <li>
                                    <a href="#!"><i class="fab fa-facebook"></i></a>
                                    <a href="#!"><i class="fab fa-twitter-square"></i></a>
                                    <a href="#!"><i class="fab fa-instagram"></i></a>
                                    <a href="#!"><i class="fab fa-linkedin"></i></a>
                                </li>
                                <li><a href="#!"><span>+011 234 567 89</span></a></li>
                                <li><a href="#!"><span>contact@domain.com</span></a></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <ul class="topbar-others-options">
                                <li><router-link to="/login">Login</router-link></li>
                                <li><router-link to="/register">Sign up</router-link></li>
                                <li>
                                    <div class="dropdown language-option">
                                        <select v-model="language">
                                            <option value="English">English</option>
                                            <option value="Arabic">Arabic</option>
                                            <option value="French">French</option>
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div class="dropdown language-option">

                                        <select v-model="currency">
                                            <option value="USD">USD</option>
                                            <option value="BD">BD</option>
                                            <option value="URO">URO</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Navbar Bar -->
            <div class="navbar-area" :class="{ 'is-sticky': isSticky }">
                <div class="main-responsive-nav">
                    <div class="container">
                        <div class="main-responsive-menu mean-container">
                            <nav class="navbar">
                                <div class="container">
                                    <div class="logo">
                                        <router-link to="/">
                                            <img src="../assets/img/logo.png" alt="logo">
                                        </router-link>
                                    </div>
                                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbar-content">
                                        <div class="hamburger-toggle">
                                            <div class="hamburger">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </button>
                                    <div class="collapse navbar-collapse mean-nav" id="navbar-content">
                                        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                                            <li class="nav-item dropdown">
                                                <router-link to="/" class="dropdown-item dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside">Home</router-link>
                                                <ul class="dropdown-menu shadow">
                                                    <li class="nav-item">
                                                        <router-link to="/" class="dropdown-item">Home One</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/home-two" class="dropdown-item">Home
                                                            Two</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <router-link to="/" class="dropdown-item dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside">Tours</router-link>
                                                <ul class="dropdown-menu shadow">
                                                    <li class="nav-item">
                                                        <router-link to="/tour-search"
                                                            class="dropdown-item">Tour</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/tour-details" class="dropdown-item">Tour
                                                            Details</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/tour-booking-submission"
                                                            class="dropdown-item">Tour
                                                            Booking</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/top-destinations" class="dropdown-item">Top
                                                            Destination</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <router-link to="/" class="dropdown-item dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside">Flights</router-link>
                                                <ul class="dropdown-menu shadow">
                                                    <li class="nav-item">
                                                        <router-link to="/flight-search-result"
                                                            class="dropdown-item">Flight</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/flight-booking-submission"
                                                            class="dropdown-item">Flight
                                                            Booking</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <router-link to="/" class="dropdown-item dropdown-toggle"
                                                    data-bs-toggle="dropdown" data-bs-auto-close="outside">Hotel
                                                </router-link>
                                                <ul class="dropdown-menu shadow">
                                                    <li class="nav-item">
                                                        <router-link to="/hotel-search"
                                                            class="dropdown-item">Hotel</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/hotel-details" class="dropdown-item">Hotel
                                                            Booking</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/room-details" class="dropdown-item">Room
                                                            Details</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/room-booking" class="dropdown-item">Room
                                                            Booking</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside">Pages</a>
                                                <ul class="dropdown-menu shadow">

                                                    <li class="nav-item">
                                                        <router-link to="/about"
                                                            class="dropdown-item">About</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/tour-guides"
                                                            class="dropdown-item">Team</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/testimonials"
                                                            class="dropdown-item">Testimonials</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/faqs" class="dropdown-item">FAQ</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/booking-confirmation"
                                                            class="dropdown-item">Booking
                                                            Confirmation</router-link>
                                                    </li>

                                                    <li class="dropend">
                                                        <a href="#" class="dropdown-item dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="outside">News</a>
                                                        <ul class="dropdown-menu shadow">

                                                            <li class="nav-item">
                                                                <router-link to="/news"
                                                                    class="dropdown-item">News</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/news-v2" class="dropdown-item">News
                                                                    v2</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/news-details"
                                                                    class="dropdown-item">News
                                                                    Details</router-link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="dropend">
                                                        <a href="#" class="dropdown-item dropdown-toggle"
                                                            data-bs-toggle="dropdown" data-bs-auto-close="outside">User
                                                            Pages</a>
                                                        <ul class="dropdown-menu shadow">

                                                            <li class="nav-item">
                                                                <router-link to="/login"
                                                                    class="dropdown-item">Login</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/register"
                                                                    class="dropdown-item">Register</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/forgot-password"
                                                                    class="dropdown-item">Forget
                                                                    Password</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/verify-otp"
                                                                    class="dropdown-item">Verify
                                                                    OTP</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/reset-password"
                                                                    class="dropdown-item">Reset
                                                                    Password</router-link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="dropend">
                                                        <a href="#" class="dropdown-item dropdown-toggle"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-auto-close="outside">Customer Dashboard</a>
                                                        <ul class="dropdown-menu shadow">

                                                            <li class="nav-item">
                                                                <router-link to="/dashboard"
                                                                    class="dropdown-item">Dashboard</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/hotel-booking"
                                                                    class="dropdown-item">Hotel
                                                                    booking</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/flight-booking"
                                                                    class="dropdown-item">Flight
                                                                    booking</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/tour-booking"
                                                                    class="dropdown-item">Tour
                                                                    booking</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/booking-history"
                                                                    class="dropdown-item">Booking
                                                                    history</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/my-profile" class="dropdown-item">My
                                                                    profile</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/wallet"
                                                                    class="dropdown-item">Wallet</router-link>
                                                            </li>
                                                            <li class="nav-item">
                                                                <router-link to="/notification"
                                                                    class="dropdown-item">Notifications</router-link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/privacy-policy" class="dropdown-item">Privacy
                                                            Policy</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/error" class="dropdown-item">404
                                                            Error</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/contact" class="dropdown-item">Contact</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="main-navbar">
                    <div class="container">
                        <nav class="navbar navbar-expand-md navbar-light">
                            <router-link class="navbar-brand" to="/">
                                <img src="../assets/img/logo.png" alt="logo">
                            </router-link>

                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link active">
                                            Home
                                            <i class="fas fa-angle-down"></i>
                                        </router-link>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/" class="nav-link">Home One</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/home-two" class="nav-link">Home Two</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Tours
                                            <i class="fas fa-angle-down"></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/tour-search" class="nav-link">Tour</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/tour-details" class="nav-link">Tour
                                                    Details</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/tour-booking-submission" class="nav-link">Tour
                                                    Booking</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/top-destinations" class="nav-link">Top
                                                    Destination</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Flights
                                            <i class="fas fa-angle-down"></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/flight-search-result"
                                                    class="nav-link">Flight</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/flight-booking-submission" class="nav-link">Flight
                                                    Booking</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Hotel
                                            <i class="fas fa-angle-down"></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/hotel-search" class="nav-link">Hotel</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/hotel-details" class="nav-link">Hotel
                                                    Booking</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/room-details" class="nav-link">Room
                                                    Details</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/room-booking" class="nav-link">Room
                                                    Booking</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Pages
                                            <i class="fas fa-angle-down"></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/about" class="nav-link">About</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/tour-guides" class="nav-link">Team</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/testimonials"
                                                    class="nav-link">Testimonials</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/faqs" class="nav-link">FAQ</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/booking-confirmation" class="nav-link">Booking
                                                    Confirmation</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <a to="#" class="nav-link">News</a>
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <router-link to="/news" class="nav-link">News</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/news-v2" class="nav-link">News
                                                            v2</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/news-details" class="nav-link">News
                                                            Details</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <a to="#" class="nav-link">User Pages</a>
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <router-link to="/login" class="nav-link">Login</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/register"
                                                            class="nav-link">Register</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/forgot-password" class="nav-link">Forget
                                                            Password</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/verify-otp" class="nav-link">Verify
                                                            OTP</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/reset-password" class="nav-link">Reset
                                                            Password</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link">Customer Dashboard</a>
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <router-link to="/dashboard"
                                                            class="nav-link">Dashboard</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/hotel-booking" class="nav-link">Hotel
                                                            booking</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/flight-booking" class="nav-link">Flight
                                                            booking</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/tour-booking" class="nav-link">Tour
                                                            booking</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/booking-history" class="nav-link">Booking
                                                            history</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/my-profile" class="nav-link">My
                                                            profile</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/wallet" class="nav-link">Wallet</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/notification"
                                                            class="nav-link">Notifications</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/privacy-policy" class="nav-link">Privacy
                                                    Policy</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/error" class="nav-link">404 Error</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/contact" class="nav-link">Contact</router-link>
                                    </li>
                                </ul>
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <router-link to="/become-vendor" class="btn  btn_navber">Become a
                                            partner</router-link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="container">
                            <div class="option-inner">
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <a href="#" class="search-box"><i class="fas fa-search"></i></a>
                                    </div>
                                    <div class="option-item">
                                        <router-link to="/contact" class="btn btn_navber">Get free quote</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
export default {
    name: 'Header',
    data() {
        return {
            language: 'English',
            currency: 'USD',
            isSticky: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', () => {
            let scroll = window.scrollY
            if (scroll >= 200) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        })

        document.addEventListener('click', function (e) {
            // Hamburger menu
            if (e.target.classList.contains('hamburger-toggle')) {
                e.target.children[0].classList.toggle('active');
            }
        })
    }
}
</script>

